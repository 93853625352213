#footer {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#footer a {
    margin-left: 5px;
    margin-right: 5px;
    color: #000000;
}