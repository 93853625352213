body {
    font-family: sans-serif;
    line-height: 1.6em;
    background-color: #FCFFEA;
}

body p {
    padding-top: 10px;
    padding-bottom: 10px;
}

h1 {
    font-size: 2em;
    padding-top: 20px;
    padding-bottom: 15px;
}

h2 {
    font-size: 1.7em;
    padding-top: 15px;
    padding-bottom: 10px;
}

h3 {
    font-size: 1.4em;
    padding-top: 10px;
    padding-bottom: 10px;
}

h1, h2, h3 {
    line-height: 1.2;
}

strong {
    font-weight: bold;
}

ol {
    list-style-type: decimal;
    list-style-position: inside;
}

ul {
    list-style-type: circle;
    list-style-position: inside;
}

i {
    font-style: italic;
}

#content-wrap {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}