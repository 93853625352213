
#logo img {
    vertical-align: middle;
}

#logo {
    font-size: 2em;
    font-family: "Avenir Black";
    margin-bottom: 50px;
}

#logo a {
    text-decoration: none;
    color: #000000;
}